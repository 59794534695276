/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendSms = /* GraphQL */ `
  mutation SendSms($msg: String, $to: String) {
    sendSms(msg: $msg, to: $to)
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      body
      from
      to
      messageSid
      accountSid
      messagingServiceSid
      numSegments
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      body
      from
      to
      messageSid
      accountSid
      messagingServiceSid
      numSegments
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      body
      from
      to
      messageSid
      accountSid
      messagingServiceSid
      numSegments
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPushSubscription = /* GraphQL */ `
  mutation CreatePushSubscription(
    $input: CreatePushSubscriptionInput!
    $condition: ModelPushSubscriptionConditionInput
  ) {
    createPushSubscription(input: $input, condition: $condition) {
      id
      userAgent
      dateCreated
      endpoint
      expirationTime
      keys {
        p256dh
        auth
      }
      _v
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePushSubscription = /* GraphQL */ `
  mutation UpdatePushSubscription(
    $input: UpdatePushSubscriptionInput!
    $condition: ModelPushSubscriptionConditionInput
  ) {
    updatePushSubscription(input: $input, condition: $condition) {
      id
      userAgent
      dateCreated
      endpoint
      expirationTime
      keys {
        p256dh
        auth
      }
      _v
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePushSubscription = /* GraphQL */ `
  mutation DeletePushSubscription(
    $input: DeletePushSubscriptionInput!
    $condition: ModelPushSubscriptionConditionInput
  ) {
    deletePushSubscription(input: $input, condition: $condition) {
      id
      userAgent
      dateCreated
      endpoint
      expirationTime
      keys {
        p256dh
        auth
      }
      _v
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      callerId
      accountSid
      authToken
      sipDomain
      sipUsername
      sipUri
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      callerId
      accountSid
      authToken
      sipDomain
      sipUsername
      sipUri
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      callerId
      accountSid
      authToken
      sipDomain
      sipUsername
      sipUri
      owner
      createdAt
      updatedAt
    }
  }
`;
