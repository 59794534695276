import styles from './styles/Loader.module.css';
import React, { useEffect, useState } from 'react';

function Loader() {
  return (
    <div className={styles.loaderContainer}>
      <p>Loading...</p>
      <div className={styles?.["lds-dual-ring"]}></div>
    </div>
  );
}

export default Loader;
