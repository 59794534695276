import styles from './styles/NavBar.module.css';
import {
  Link,
} from "react-router-dom";
import { Auth } from 'aws-amplify';



const NavBar = () => {
  return (
    <div className={styles.nav}>
      <Link to={`/`} className={styles.button}>All</Link>
      <Link to={`/settings`} className={styles.button}>Settings</Link>
      <Link to={`/`} onClick={() => Auth.signOut()} className={styles.button}>Sign out</Link>
    </div>
  )
};

export default NavBar;