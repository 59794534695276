/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      body
      from
      to
      messageSid
      accountSid
      messagingServiceSid
      numSegments
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        body
        from
        to
        messageSid
        accountSid
        messagingServiceSid
        numSegments
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPushSubscription = /* GraphQL */ `
  query GetPushSubscription($id: ID!) {
    getPushSubscription(id: $id) {
      id
      userAgent
      dateCreated
      endpoint
      expirationTime
      keys {
        p256dh
        auth
      }
      _v
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPushSubscriptions = /* GraphQL */ `
  query ListPushSubscriptions(
    $filter: ModelPushSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPushSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userAgent
        dateCreated
        endpoint
        expirationTime
        keys {
          p256dh
          auth
        }
        _v
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      callerId
      accountSid
      authToken
      sipDomain
      sipUsername
      sipUri
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        callerId
        accountSid
        authToken
        sipDomain
        sipUsername
        sipUri
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const profilesByCallerId = /* GraphQL */ `
  query ProfilesByCallerId(
    $callerId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profilesByCallerId(
      callerId: $callerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        callerId
        accountSid
        authToken
        sipDomain
        sipUsername
        sipUri
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const profilesBySipUri = /* GraphQL */ `
  query ProfilesBySipUri(
    $sipUri: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profilesBySipUri(
      sipUri: $sipUri
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        callerId
        accountSid
        authToken
        sipDomain
        sipUsername
        sipUri
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const profilesByOwner = /* GraphQL */ `
  query ProfilesByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profilesByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        callerId
        accountSid
        authToken
        sipDomain
        sipUsername
        sipUri
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
