import styles from './styles/Main.module.css';
import { API, graphqlOperation } from 'aws-amplify';
import { sendSms } from './graphql/mutations';
import {v4} from 'uuid';
import { useEffect, useState } from 'react';
import { onCreateMessage } from './graphql/subscriptions';
import { listMessages, listProfiles } from './graphql/queries';
import {Link, useParams} from "react-router-dom";


const Settings = ({profile}) => {
  
  return (
    <div>
      <h1>Settings</h1>
      <h2>My Number</h2>
      <h3>{profile?.callerId}</h3>
    </div>
  )
};

export default Settings;
