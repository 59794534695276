import styles from './styles/Main.module.css';
import { API, graphqlOperation } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { listMessages } from './graphql/queries';
import {Link, useNavigate} from "react-router-dom";
import Loader from './Loader';

const Main = ({profile}) => {
  const [messages, setMessages] = useState([]); 
  const [conversations, setConversations] = useState([]);  
  const [isLoading, setIsLoading] = useState(true);
  const [newNumber, setNewNumber] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // setIsLoading(true);
    if (!profile?.callerId) return;
    const myNumber = profile.callerId;
    const conversations = {};

    // Group messages by conversation partner
    for (const message of messages) {
      const partner = message.from === myNumber ? message.to : message.from;
  
      if (!conversations[partner]) {
        conversations[partner] = [];
      }
  
      conversations[partner].push(message);
    }
  
    // Sort messages within each conversation by time, and keep the last one
    for (const partner in conversations) {
      conversations[partner].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      conversations[partner] = conversations[partner][0];
    }
  
    // Convert the conversations object into an array and sort by time
    const conversationList = Object.entries(conversations)
      .map(([partner, lastMessage]) => ({ partner, lastMessage }))
      .sort((a, b) => new Date(b.lastMessage.updatedAt) - new Date(a.lastMessage.updatedAt));
  
    // console.log("before set");
    setConversations(conversationList);
    // console.log("after set");

    // setIsLoading(false);
  }, [messages, profile]);

  useEffect(() => {
    setIsLoading(true);
    API.graphql(graphqlOperation(listMessages)).then((res) => {
      setMessages(res.data.listMessages.items);
      setIsLoading(false);
    });
    
    // const subscription = API.graphql(
    //   graphqlOperation(onCreateMessage)
    // ).subscribe({
    //   next: (eventData) => {
    //     const message = eventData.value.data.onCreateMessage;
    //     setMessages((prevMessages) => {
    //       const oldMessages = prevMessages.filter(
    //         (msg) => msg.id !== message.id
    //       );
    //       const newMessages = [...oldMessages, message];
    //       return newMessages;
    //     });
    //   },
    // });
    // return () => subscription.unsubscribe();
  }, []);

  const createConversation = (newNumber) => {
    //check number starts with a plus sign and is at least 7 digits
    if (!newNumber?.match(/^\+[0-9]{7,15}$/)) return;
    navigate(`/conversations/${newNumber}`);
  };

  return (
    <div>
      <h2>Conversations</h2>
      <form 
        className={styles.newConversationContainer}
        onSubmit={(e) => {e.preventDefault(); createConversation(newNumber)}}
      >
        <input 
          className={styles.newConversation}
          placeholder="Type a number"
          type="tel"
          pattern="\+[0-9]{7,15}$"
          onChange={(e) => {setNewNumber(e.target.value)}}
        />
        <button className={styles.newConversationButton} type="submit">Go</button>
      </form>
      <ul className={styles.conversationsContainer}>
        {
          isLoading ? <Loader /> :
          conversations.map((conversation, index) => (
            <Link key={index} to={`/conversations/${conversation.partner}`} className={styles.conversationLink}>
              <li className={styles.conversation}>
                <h3>{conversation.partner}</h3>
                <p>{conversation.lastMessage.body}</p>
              </li>
            </Link>
          ))
        }
      </ul>
      {/* <p>
      window.navigator.userAgent: {window.navigator.userAgent}
      </p> */}
    </div>
  )
};

export default Main;