// import './App.css';
import React, { useEffect, useState } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import awsConfig from './aws-exports';
import Main from './Main';
import NavBar from './NavBar';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Conversation from './Conversation';
import { API, graphqlOperation } from 'aws-amplify';
import { listProfiles } from './graphql/queries';
import Settings from './Settings';
import Loader from './Loader';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(',');

const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(',');

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

Amplify.configure(updatedAwsConfig);

function App() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });

    getUser().then(userData => setUser(userData));
  }, []);

  useEffect(() => {
    if (!user) return;
    API.graphql(graphqlOperation(listProfiles)).then((res) => {
      const profile = res.data.listProfiles.items.find((profile) => profile.owner === user.username);
      setProfile(profile);
    });
  }, [user]);

  async function getUser() {
    setIsLoading(true)
    let userData = null;
    try {
       userData = await Auth.currentAuthenticatedUser();
    } catch {
      console.log('Not signed in');
    }
    setIsLoading(false)
    return userData;
  }

  return (
    user && profile ? (
      <div className="App">
        <BrowserRouter>
          <NavBar />
          <div className="Container">
            <Routes>
              <Route path="/" element={<Main profile={profile} />} />
              <Route path="/conversations/:partnerNumber" element={<Conversation profile={profile} />} />
              <Route path="/settings" element={<Settings profile={profile} />} />
            </Routes>
          </div>
        </BrowserRouter>
        {/* <p>Hello, {user.username}</p>
        <button onClick={() => Auth.signOut()}>Sign out</button>
        <Main /> */}
      </div>
    ) : (
      isLoading ? <Loader /> : (
        <div className="App">
          <h2>Softphone Service</h2>
          <button onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })}>Sign in with Google</button>
        </div>
      )
    )
  );
}

export default App;
