import styles from './styles/Main.module.css';
import { API, graphqlOperation } from 'aws-amplify';
import { sendSms } from './graphql/mutations';
import { useEffect, useState } from 'react';
import { listMessages } from './graphql/queries';
import {useParams} from "react-router-dom";
import _ from 'lodash';
import Loader from './Loader';

const Conversation = ({profile}) => {
  const [isLoading, setIsLoading] = useState(true);
  const partnerNumber = useParams().partnerNumber;
  const [messages, setMessages] = useState([]);
  const [newMessageBody, setNewMessageBody] = useState('');
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    loadMessages();
  }, [partnerNumber]);

  const loadMessages = async () => {
    setIsLoading(true);
    API.graphql(graphqlOperation(listMessages, { filter: {
      or: [
        { from: { eq: partnerNumber } },
        { to: { eq: partnerNumber } }
      ]
    }})).then((res) => {
      const messages = res.data.listMessages.items;
      setMessages(_.sortBy(messages, ['updatedAt']));
      setIsLoading(false);
    });
  };

  const addMessage = async () => {
    if (!newMessageBody) return;
    setIsSending(true);
    const newMessage = await API.graphql(graphqlOperation(sendSms, { 
      msg: newMessageBody,
      to: partnerNumber,
    }));
    setIsSending(false);
    loadMessages();
    return newMessage;
  };

  return (
    <div>
      <h2>{partnerNumber}</h2>
      <div className={styles.conversationsContainer}>
        {
          isLoading ? <Loader /> :
          messages.map((message, index) => (
            <div key={index} className={`${styles.message} ${message.from === profile?.callerId ? styles.sent : styles.received}`}>
              <p>{message.body}</p>
            </div>
          ))
        }
      </div>
      {
        isSending ? <div>Sending...</div> : <form className={styles.newMessage} onSubmit={(e) => {e.preventDefault(); addMessage()}}>
          <textarea className={styles.newMessageText} placeholder="Write Message..." onChange={(e) => {setNewMessageBody(e.target.value)}} />
          <button type="submit" className={styles.newMessageButton}>Send</button>
        </form>
      }
      
    </div>
  )
};

export default Conversation;
